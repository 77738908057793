import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Project } from '../projects/domain/project';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { ProjectList } from '../projects/domain/projectList';
import { SearchProjectData } from '../projects/domain/search-project-data';
import { PhaseAndEmployees } from '../projects/domain/phase-and-employees';
import { PhaseWithEmployees } from '../projects/domain/phase-with-employees';
import { ConflictingProject } from '../projects/domain/conflicting-project';
import { EmployeeInProject } from '../projects/domain/employee-in-project';
import { EmployeeConflict } from '../shared/models/employee-conflict';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private projectCache: Map<string, Observable<Project>> = new Map();
  private projectCacheRefreshers: Map<string, BehaviorSubject<void>> = new Map();

  private phasesCache: Map<string, Observable<PhaseWithEmployees[]>> = new Map();
  private phasesCacheRefreshers: Map<string, BehaviorSubject<void>> = new Map();

  constructor(private httpClient: HttpClient) {}

  getProject(projectId: string): Observable<Project> {
    if (!this.projectCache.has(projectId)) {
      this.projectCacheRefreshers.set(projectId, new BehaviorSubject<void>(undefined));

      this.projectCache.set(projectId,
        this.projectCacheRefreshers
          .get(projectId)
          .pipe(
            switchMap(() => this.httpClient.get<Project>(`${ environment.api }/project/${ projectId }`)),
            shareReplay(1),
          ),
      );
    }

    return this.projectCache.get(projectId);
  }

  refreshProject(projectId: string): void {
    this.projectCacheRefreshers.get(projectId)?.next();
    this.phasesCacheRefreshers.get(projectId)?.next();
  }

  getProjectPhasesWithEmployees(projectId: string): Observable<PhaseWithEmployees[]> {
    if (!this.phasesCache.has(projectId)) {
      this.phasesCacheRefreshers.set(projectId, new BehaviorSubject<void>(undefined));

      this.phasesCache.set(projectId,
        this.phasesCacheRefreshers
          .get(projectId)
          .pipe(
            switchMap(() => this.httpClient.get<PhaseWithEmployees[]>(`${ environment.api }/project/${ projectId }/phase-employee`)),
            shareReplay(1),
          ),
      );
    }

    return this.phasesCache.get(projectId);
  }

  getProjectPhaseWithEmployees(projectId: string, phaseId: string): Observable<PhaseWithEmployees> {
    return this.getProjectPhasesWithEmployees(projectId).pipe(
      map((phases) => phases.find((phase) => phase.id === phaseId)),
    );
  }

  createProject(project: Project): Observable<Project> {
    const value = Object({ ...project });
    return this.httpClient.post<Project>(`${ environment.api }/project`, value);
  }

  editProject(project: Project): Observable<Project> {
    const value = Object({ ...project });
    return this.httpClient.put<Project>(`${ environment.api }/project/${ project.id }`, value);
  }

  getProjectList(data: Partial<SearchProjectData>): Observable<ProjectList> {
    let searchString = '';
    searchString += data.search ? `&search=${ data.search }` : '';
    searchString += data.clientId ? `&clientId=${ data.clientId }` : '';
    searchString += data.vesselId ? `&vesselId=${ data.vesselId }` : '';
    searchString += data.consultantId ? `&consultantId=${ data.consultantId }` : '';
    searchString += data.statusId ? `&statusId=${ data.statusId }` : '';
    searchString += data.managerId ? `&managerId=${ data.managerId }` : '';
    searchString += data.active ? `&sort=${data.active}` : '';
    searchString += data.direction ? `&order=${data.direction}` : '';


    return this.httpClient.get<ProjectList>(
      `${ environment.api }/project?limit=${ data.pageSize }&page=${ data.pageIndex + 1 }${ searchString }`,
    );
  }

  addPhase(phase: PhaseAndEmployees, projectId: string): Observable<string> {
    return this.httpClient.post<string>(`${ environment.api }/project/${ projectId }/phase`, phase);
  }

  editPhase(phase: PhaseAndEmployees, projectId: string): Observable<string> {
    return this.httpClient.put<string>(`${ environment.api }/project/${ projectId }/phase/${ phase.id }`, phase);
  }

  editPhaseWithEmployees(phase: PhaseAndEmployees, projectId: string, employeesToEdit: string[]) {
    return this.httpClient.put<string>(`${ environment.api }/project/${ projectId }/phase/${ phase.id }/with-employees`,
      { ...phase, employeeEntryIds: employeesToEdit });
  }

  updateProjectStatus(projectId, statusId): Observable<{}> {
    return this.httpClient.put<{}>(`${ environment.api }/project/${ projectId }/status`, { statusId });
  }

  getConflictingProjectsList(projectId: string, employeeOnPhaseId: string): Observable<ConflictingProject[]> {
    return this.httpClient.get<ConflictingProject[]>(
      `${ environment.api }/project/${ projectId }/employee-in-phase/${ employeeOnPhaseId }/conflict-projects`,
    );
  }

  checkConflictWithNewPhaseDates(projectId: string, phaseId: string, startDate: string, endDate: string): Observable<EmployeeConflict[]> {
    return this.httpClient.get<EmployeeConflict[]>(
      `${ environment.api }/project/${ projectId }/phase/${ phaseId }/conflict/${ startDate }/${ endDate }`,
    );
  }

  getEmployeeInProjectList(projectId: string): Observable<EmployeeInProject[]> {
    return this.httpClient.get<EmployeeInProject[]>(
      `${ environment.api }/project/${ projectId }/employee`,
    );
  }

  removeEmployeeFromProject(employeeIds: string[], projectId: string) {
    return this.httpClient.request('delete', `${ environment.api }/project/${ projectId }/employee`,
      { body: { employeeIds } });
  }

  removeProjects(projectIds: string[]): Observable<{}> {
    return this.httpClient.request('delete', `${ environment.api }/project`, { body: { projectIds } });
  }

  removePhaseFromProject(projectId: string, phaseId: string): Observable<{}> {
    return this.httpClient.delete(`${ environment.api }/project/${ projectId }/phase/${ phaseId }`);
  }
}
