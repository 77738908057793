import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { AssignEmployeeService } from '../../services/assign-employee.service';
import { map, switchMap, take } from 'rxjs/operators';
import { EmployeeAssignStep } from '../../shared/assign-employee/employee-assign-step';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfessionService } from '../../../core/profession.service';
import { PhaseWithEmployees } from '../domain/phase-with-employees';
import { NotificationService } from '../../../core/notification.service';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'app-assign-employee',
  template: `
    <ng-container [ngSwitch]="step$.asObservable() | async">
      <app-phases-step
        *ngSwitchCase="'phases'"
        [isPreviousStep]="false"
        [isNextStep]="true"
        [phases]="(project$ | async)?.phases"
        (closeEmployeeAssign)="onCloseEmployeeAssign()"
        (phaseSelected)="onPhaseSelected($event)"
      ></app-phases-step>
      <app-employees-step
        *ngSwitchCase="'employees'"
        [isPreviousStep]="true"
        [professions]="professions$ | async"
        [projectId]="(project$ | async)?.id"
        [phase]="phase$ | async"
        (closeEmployeeAssign)="onCloseEmployeeAssign()"
        (backToPreviousStep)="setStep('phases')"
        (employeeSelected)="onEmployeeSelected($event)"
      ></app-employees-step>
    </ng-container>
  `,
})
export class AssignEmployeeFromProjectComponent implements OnInit {
  step$ = new BehaviorSubject<EmployeeAssignStep>('phases');

  professions$ = this.professionService.professions$;

  project$ = this.assignEmployeeService.project$;
  phase$ = this.assignEmployeeService.phase$;
  employeeIds$ = this.assignEmployeeService.employeeIds$;

  constructor(
    private readonly assignEmployeeService: AssignEmployeeService,
    private readonly router: Router,
    private readonly professionService: ProfessionService,
    private readonly notificationService: NotificationService,
    private readonly route: ActivatedRoute,
    private readonly projectService: ProjectService,
  ) {}

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        take(1),
        map((paramMap) => paramMap.get('projectId')),
        switchMap((projectId) => this.projectService.getProject(projectId)),
      )
      .subscribe((project) => this.assignEmployeeService.setProject(project));
  }

  setStep(value: EmployeeAssignStep): void {
    this.step$.next(value);
  }

  onCloseEmployeeAssign(): void {
    this.route.url
      .pipe(take(1))
      .subscribe((url) => {
        if (url.some((segment) => segment.path === 'project-details'))
          this.navigateToProjectDetails();
        else if (url.some((segment) => segment.path === 'list'))
          this.navigateToProjectList();
      });
  }

  onPhaseSelected(phase: PhaseWithEmployees): void {
    this.assignEmployeeService.setPhase(phase);
    this.setStep('employees');
  }

  onEmployeeSelected(employeeIds: string[]): void {
    this.assignEmployeeService.setEmployeeIds(employeeIds);

    combineLatest([this.project$, this.phase$, this.employeeIds$])
      .pipe(
        take(1),
        map(([project, phase, employeeIds]): [string, string, string[]] =>
          [project.id, phase.id, employeeIds]
        ),
      )
      .subscribe(([projectId, phaseId, employeeIds]) =>
          this.assignEmployeeToPhase(projectId, phaseId, employeeIds)
      )
  }

  private assignEmployeeToPhase(projectId: string, phaseId: string, employeeIds: string[]) {
    this.assignEmployeeService.assignEmployeeToPhase(<string[]>employeeIds, <string>projectId, <string>phaseId)
      .pipe(take(1))
      .subscribe(() => {
        this.projectService.refreshProject(projectId);
        this.notificationService.setNotification('Employee was added to project');
        this.onCloseEmployeeAssign();
      },
        (error) => {
        console.error(error);
          const errorMessage = error?.error?.msg || 'An error occurred while assigning the employee.';
          this.notificationService.setNotification(errorMessage);
      });
  }

  private navigateToProjectDetails(): void {
    this.project$
      .pipe(
        take(1),
        map((project => project.id)),
      )
      .subscribe((projectId) =>
        this.router.navigate(['/main', 'projects', 'project-details', projectId]),
      );
  }

  private navigateToProjectList(): void {
    this.router.navigate(['/main', 'projects', 'list']);
  }
}
