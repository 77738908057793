import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmployeeService } from '../../../employees/services/employee.service';
import { debounceTime, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { SearchEmployeeData } from '../../../employees/domain/search-employee-data';
import { AssignEmployeeService } from '../../../services/assign-employee.service';
import { Profession } from '../../../employees/domain/profession';
import { BehaviorSubject, combineLatestWith } from 'rxjs';
import { MatSelectionListChange } from '@angular/material/list';
import { EmployeeConflict } from '../../models/employee-conflict';
import { PhaseWithEmployees } from '../../../projects/domain/phase-with-employees';
import { EmployeeListItem } from '../../../employees/domain/employee-list';
import { Rating, RatingCategory } from '../../../projects/domain/employee-assessment';
import { EmployeesAssessmentsService } from '../../../projects/services/employees-assessments.service';
import { RatingForm } from '../../../projects/domain/employee-assessment-form';

interface EmployeeFiltersForm {
  search: FormControl<string>;
  availableOnly: FormControl<boolean>,
  professionId: FormControl<number | null>,
  ratings: FormArray<FormControl<number>>,
}

interface EmployeeFiltersValue {
  search: string;
  availableOnly: boolean,
  professionId: number | null,
  ratings: Array<number | null>,
}

@Component({
  selector: 'app-employees-step',
  templateUrl: 'employees-step.component.html',
  styleUrls: ['../employee-assign.scss'],
})
export class EmployeesStepComponent implements OnInit {
  @Input() isPreviousStep: boolean;
  @Input() professions: Profession[];
  @Input() projectId: string;
  @Input() phase: PhaseWithEmployees;

  @Output() closeEmployeeAssign = new EventEmitter<void>();
  @Output() backToPreviousStep = new EventEmitter<void>();
  @Output() employeeSelected = new EventEmitter<string[]>();

  employeeFilterForm: FormGroup<EmployeeFiltersForm> = new FormGroup({
    search: new FormControl<string>('', { nonNullable: true }),
    availableOnly: new FormControl<boolean>(false, { nonNullable: true }),
    professionId: new FormControl<number | null>(null),
    ratings: new FormArray<FormControl<number>>([]),
  });
  employeeForm = new FormGroup({
    employeeId: new FormControl<string[]>([], Validators.required),
  });

  readonly ratingCategories$ = this.assessmentsService.ratingCategories$;
  readonly moreFiltersVisible = new BehaviorSubject<boolean>(false);
  readonly moreFiltersVisible$ = this.moreFiltersVisible.asObservable();

  readonly employees$ = this.employeeFilterForm.valueChanges.pipe(
    debounceTime(300),
    filter(() => this.employeeFilterForm.valid),
    startWith({ search: '', ratings: [] }),
    combineLatestWith(this.ratingCategories$),
    map(([filterFormValue, ratingCategories]: [Partial<EmployeeFiltersValue>, RatingCategory[]]): [Partial<EmployeeFiltersValue>, Partial<SearchEmployeeData>] =>
      [filterFormValue, this.mapToSearchEmployeeData(filterFormValue, ratingCategories)],
    ),
    switchMap(([filterFormValue, filters]) =>
      this.employeeService.getEmployeeList(<Partial<SearchEmployeeData>>filters)
        .pipe(
          map((employees) => employees.items),
          map((employees) =>
            filterFormValue.availableOnly ? employees.filter((employee) => employee.isAvailable) : employees,
          ),
          map((employees) => employees.filter((employee) => employee.status.status != 'Blocked')),
        ),
    ),
    tap(() => this.employeeForm.reset()),
    tap(() => this.conflicts$.next([])),
  );
  readonly conflicts$ = new BehaviorSubject<EmployeeConflict[]>([]);

  get ratingControlsArray(): FormArray<FormControl<number>> {
    return this.employeeFilterForm.controls.ratings;
  }

  constructor(
    private readonly employeeService: EmployeeService,
    private readonly assignEmployeeService: AssignEmployeeService,
    private readonly assessmentsService: EmployeesAssessmentsService,
  ) {}

  ngOnInit(): void {
    this.ratingCategories$
      .pipe(take(1))
      .subscribe((ratingCategories) =>
        this.addRatingCategoriesControlsToForm(ratingCategories),
      );
  }

  onEmployeeAssignClose(): void {
    this.closeEmployeeAssign.emit();
  }

  goBackToPreviousStep(): void {
    this.backToPreviousStep.emit();
  }

  onEmployeeSelect(): void {
    this.employeeSelected.emit(this.employeeForm.getRawValue().employeeId);
  }

  onEmployeeSelectionChange(event: MatSelectionListChange) {
    const employeeId = event.options[0].value;
    this.assignEmployeeService.checkConflictsWithProject(this.projectId, this.phase.id, [employeeId])
      .pipe(take(1))
      .subscribe({
        next: () => this.conflicts$.next([]),
        error: (error) => this.conflicts$.next(error.error),
      });
  }

  employeeIdTracker(index: number, item: EmployeeListItem): string {
    return item.id;
  }

  ratingCategoryIdTracker(index: number, item: Rating): number {
    return item.categoryId;
  }

  professionIdTracker(index: number, item: Profession): number {
    return item.id;
  }

  private addRatingCategoriesControlsToForm(ratingCategories: RatingCategory[]): void {
    ratingCategories.forEach((category) => {
      const ratingControl = new FormControl<number>(
        null,
        [Validators.min(category.min), Validators.max(category.max)],
      );

      this.employeeFilterForm.controls.ratings.setControl(category.id, ratingControl);
    });
  }

  private mapToSearchEmployeeData(
    filterFormValue: Partial<EmployeeFiltersValue>,
    ratingCategories: RatingCategory[]
  ): Partial<SearchEmployeeData> {
    const mappedRatings: RatingForm[] = filterFormValue.ratings
      .map((rating, index) => ({
          categoryId: ratingCategories[index].id,
          value: rating,
        })
      );

    return {
      pageIndex: 0,
      pageSize: 8,
      search: filterFormValue.search ? filterFormValue.search : null,
      professionId: filterFormValue.professionId,
      availableFrom: this.phase.startDate,
      availableTo: this.phase.endDate,
      ratings: mappedRatings,
    };
  }
}
